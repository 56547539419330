<template>
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-table class="a-table" :data="tableData">
      <el-table-column label="名称" prop="userName" />
      <el-table-column label="联系方式" prop="contact" />
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isApproved" type="success" size="medium"
            >已通过</el-tag
          >
          <el-tag v-else size="medium">未通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="updateTime" />
      <el-table-column label="创建时间" prop="createTime" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="handleAccept(scope.row)" size="mini" type="primary"
            >通过</el-button
          >
          <el-button @click="handleDisable(scope.row)" size="mini" type="danger"
            >禁用</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="a-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="layout"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      /**分页 */
      pageInfo: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        layout: "total, sizes, prev, pager, next, jumper",
        currentPage: 1,
        total: 1,
      },
      loading: true,
    };
  },
  methods: {
    getUserList() {
      this.loading = true;
      const page = {
        pageIndex: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
      };
      this.$axios.getCustomerList({ ...page }).then((res) => {
        if (!res.error) {
          this.pageInfo.total = res.totalCount;
          this.tableData = res.data;
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
        this.loading = false;
      });
    },
    /**操作处理 */
    handleAccept(row) {
      this.$axios.approvalCustomer({ username: row.userName }).then((res) => {
        if (!res.error) {
          this.$message({
            message: "用户通过成功",
            type: "success",
          });
          this.getUserList();
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
      });
    },
    handleDisable(row) {
      this.$axios.disableCustomer({ username: row.userName }).then((res) => {
        if (!res.error) {
          this.$message({
            message: "用户禁用成功",
            type: "success",
          });
          this.getUserList();
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
      });
    },
    /**分页 */
    // 页面尺寸改变
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getUserList();
    },
    // 当前页改变
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
      this.getUserList();
    },
  },
  mounted() {
    this.getUserList();
  },
};
</script>
